import SimpleSchema from 'simpl-schema';

export default {
  _id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  clave_servidor_publico: {
    label: 'Clave de servidor público',
    type: String
  },
  curp: {
    label: 'CURP',
    type: String
  },
  estado: {
    label: 'Estado',
    type: String
  },
  unidad_administrativa: {
    label: 'Unidad administrativa',
    type: String
  },
  adscripcion: {
    label: 'Adscripción',
    type: String
  },
  categoria: {
    label: 'Categoría',
    type: String
  },
  photo: {
    label: 'Foto <strong style="font-size: 14px;">max(3000x3000px)</strong>',
    type: SimpleSchema.Any,
    optional: true,
    image: true,
    maxWidth: 3000,
    maxHeight: 3000,
  },
};
